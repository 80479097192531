import { makeStyles } from '@admitkard/ccl/style';
import Image from 'next/image';
import React from 'react';

const useStyles = makeStyles(
  (theme) => ({
    outerContainer: {
      width: '100%',
      boxSizing: 'border-box',
      color: theme.colorPalette.indigo.main,
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 400,
    },
    mobileView: {
      backgroundColor: 'hsla(163, 62%, 89%, 1)',
      borderRadius: '1rem',
      margin: '0 1rem',
      '@media only screen and (max-width: 426px)': {
        display: 'block',
      },
      '@media only screen and (min-width: 426px)': {
        display: 'none',
      },
    },
    desktopView: {
      background: '#F7EFFF',
      borderRadius: '1.75rem',
      height: '33.75rem',
      '@media only screen and (max-width: 426px)': {
        display: 'none',
      },
      '@media only screen and (min-width: 426px)': {
        display: 'flex',
      },
    },
    cardButtonContainer: {
      zIndex: 1,
      height: '3rem',
      width: 'max-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '1rem 1rem 1rem 1.25rem',
      gap: '0.75rem',
      background: theme.colorPalette.indigo.main,
      borderRadius: '1.875rem',
      boxSizing: 'border-box',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontWeight: 400,
      color: '#F7EFFF',
      border: 'none',
      textDecoration: 'none',
      '&:hover': {
        transform: 'translateY(-0.2rem)',
        boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
      },
      '@media screen and (min-width: 992px)': {
        fontSize: '1.1rem',
        height: '3.5rem',
      },
    },
    cardButtonIconContainer: {
      height: '1.25rem',
      aspectRatio: '1',
      '@media screen and (min-width: 992px)': {
        height: '1.5rem',
      },
      '& svg': {
        height: 'inherit',
        width: 'inherit',
      },
    },
    courseFinderDesktopImageContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: '1',
      position: 'relative',
      '& img': {
        objectFit: 'cover',
        borderRadius: '0rem 1.75rem'
      },
    },
  }),
  { name: 'CourseFinderSection' }
);

const CourseFinderSection: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      {renderMobileView()}
      {renderDesktopView()}
    </div>
  );

  function renderDesktopView() {
    return (
      <div className={classes.desktopView}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0.625rem',
            borderRadius: '1.25rem',
            backgroundColor: 'white',
            flex: '1',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              padding: '0 3rem',
            }}
          >
            <span
              style={{
                fontSize: '2rem',
                marginBottom: '1rem',
              }}
            >
              coursefinder
            </span>
            <span
              style={{
                fontWeight: 400,
                fontSize: '1.5rem',
                lineHeight: '140%',
              }}
            >
              know the chance of admit
              <span style={{ display: 'block' }}>at your dream university with</span>
              <span style={{ color: 'hsla(266, 100%, 67%, 1)' }}>our course finder</span>
            </span>
            <div style={{ marginTop: '3rem', marginBottom: '1.375rem' }}>
              <a id="cta-course-finder" href="/paf" className={classes.cardButtonContainer}>
                Check admit probability
                <span className={classes.cardButtonIconContainer}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="12" fill="#F7EFFF" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.8425 11.8281C12.9207 11.75 12.9207 11.6234 12.8425 11.5453L9.69108 8.39379C9.49582 8.19853 9.49582 7.88195 9.69108 7.68668C9.88634 7.49142 10.2029 7.49142 10.3982 7.68668L14.0446 11.3331C14.2399 11.5284 14.2399 11.845 14.0446 12.0402L10.3982 15.6867C10.2029 15.8819 9.88634 15.8819 9.69108 15.6867C9.49582 15.4914 9.49581 15.1748 9.69108 14.9796L12.8425 11.8281Z"
                      fill="#483287"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className={classes.courseFinderDesktopImageContainer}>
          <Image width={565} height={540} src="https://assets.admitkard.com/images/course-finder-desktop.webp" />
        </div>
      </div>
    );
  }

  function renderMobileView() {
    return (
      <div className={classes.mobileView}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Image height={250} width={358} src="https://assets.admitkard.com/images/course-finder-mobile.webp" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '1rem',
          }}
        >
          <span
            style={{
              fontSize: '1.25rem',
              marginBottom: '1rem',
            }}
          >
            coursefinder
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: '1.25rem',
              lineHeight: '140%',
            }}
          >
            know the chance of admit
            <span style={{ display: 'block' }}>at your dream university</span>
            <span>
              with our{' '}
              <span
                style={{
                  textDecoration: 'underline',
                }}
              >
                course finder
              </span>
            </span>
          </span>
          <div style={{ marginTop: '3rem', marginBottom: '1.375rem' }}>
            <a href="/paf" className={classes.cardButtonContainer}>
              Check admit probability
              <span className={classes.cardButtonIconContainer}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#F7EFFF" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8425 11.8281C12.9207 11.75 12.9207 11.6234 12.8425 11.5453L9.69108 8.39379C9.49582 8.19853 9.49582 7.88195 9.69108 7.68668C9.88634 7.49142 10.2029 7.49142 10.3982 7.68668L14.0446 11.3331C14.2399 11.5284 14.2399 11.845 14.0446 12.0402L10.3982 15.6867C10.2029 15.8819 9.88634 15.8819 9.69108 15.6867C9.49582 15.4914 9.49581 15.1748 9.69108 14.9796L12.8425 11.8281Z"
                    fill="#483287"
                  />
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default CourseFinderSection;
